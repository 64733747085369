import * as React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import logo from "../images/wojmal.svg"
import phone from "../images/icons/phone.svg"
import homePhone from "../images/icons/home-phone.svg"
import envelope from "../images/icons/envelope.svg"
import menu from "../images/icons/menu.svg"

function Header() { 
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header className="bg-primary relative">
      <div className={`${showMenu ? "" : "hidden"} absolute md:hidden w-full bg-primary top-full`}>
        <div className="container">
          <div className="py-10 flex flex-col text-white">
            <Link to="/" className="py-4 border-b border-blue-400" 
              activeClassName="">01. <span className="font-bold">Główna</span></Link>
            <Link to="/oferta" partiallyActive={true} className="py-4 border-b border-blue-400" 
              activeClassName="">02. <span className="font-bold">Oferta</span></Link>
            <Link to="/cennik" className="py-4 border-b border-blue-400" 
              activeClassName="">03. <span className="font-bold">Cennik</span></Link>
            <Link to="/kontakt" className="py-4 border-b-4 border-transparent" 
              activeClassName="">04. <span className="font-bold">Kontakt</span></Link>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="hidden lg:flex justify-between py-4 border-b border-blue-400 text-blue-100">
          <div className="">32-400 Myślenice, ul. Sienkiewicza 32</div>
          <div className="flex items-center">
            <div className="mr-8 flex items-center"><img src={phone} className="mr-2" /><span>606 135 787</span></div>
            <div className="mr-8 flex items-center"><img src={homePhone} className="mr-2"/><span>12 274 20 84</span></div>
            <div className="flex items-center"><img src={envelope} className="mr-2"/><span>biuro@wojmal.pl</span></div>
          </div>
        </div>
        <div className="py-4 md:py-10 flex justify-between">
          <div>
            <img className="w-28 lg:w-fit" src={logo} alt="Logo" />
          </div>
          <div className="flex items-center md:hidden" onClick={() => setShowMenu(!showMenu)}>
            <img className="w-8 cursor-pointer" src={menu} alt="Menu" />
          </div>
          <div className="hidden md:flex items-center text-white">
            <Link to="/" className="py-1 mr-10 border-b-4 border-transparent hover:border-yellow-500 transition duration-150" 
              activeClassName="border-yellow-500">01. <span className="font-bold">Główna</span></Link>
            <Link to="/oferta" partiallyActive={true} className="py-1 mr-10 border-b-4 border-transparent hover:border-yellow-500 transition duration-150" 
              activeClassName="border-yellow-500">02. <span className="font-bold">Oferta</span></Link>
            <Link to="/cennik" className="py-1 mr-10 border-b-4 border-transparent hover:border-yellow-500 transition duration-150" 
              activeClassName="border-yellow-500">03. <span className="font-bold">Cennik</span></Link>
            <Link to="/kontakt" className="py-1 border-b-4 border-transparent hover:border-yellow-500 transition duration-150" 
              activeClassName="border-yellow-500">04. <span className="font-bold">Kontakt</span></Link>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
